<template>
  <div class="hold-transition">
    <div class="modal fade" id="modal_form_solicitud_vehiculos">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar listado Solicitud Vehículos
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group">
                    <div class="form-group row">
                      <label
                        for="fecha_final"
                        class="col-md-5"
                        style="font-size: 20px"
                        >Fecha Creación</label
                      >

                      <input
                        type="date"
                        v-model="filters.fecha_creacion"
                        class="form-control form-control-sm col-md-7"
                      />
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Placa</label
                      >
                      <input
                        type="text"
                        v-model="filters.placa"
                        class="form-control form-control-sm col-md-7"
                      />
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Tipo Vehiculo</label
                      >
                      <v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="tipo_vh"
                        @input="selectTipoVh()"
                        placeholder="Tipo Vehículo"
                        label="nombre"
                        :options="listsForms.tipos_vehiculos"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Clase Vehículo</label
                      ><v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="clase_vh"
                        @input="selectClaseVh()"
                        placeholder="Clase Vehículo"
                        label="descripcion"
                        :options="$parent.listsForms.clases_vehiculos"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Email</label
                      ><input
                        type="text"
                        v-model="filters.email"
                        class="form-control form-control-sm col-md-7"
                      />
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Empresa</label
                      ><v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="empresa"
                        @input="selectEmpresa()"
                        placeholder="Empresa"
                        label="descripcion"
                        :options="$parent.listsForms.empresas"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Activo</label
                      ><v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="activo"
                        placeholder="Activo"
                        @input="selectActivo()"
                        label="descripcion"
                        :options="$parent.listsForms.activos"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Area</label
                      ><v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="area"
                        placeholder="Area"
                        label="descripcion"
                        @input="selectArea()"
                        :options="listsForms.areas"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Sitio</label
                      ><v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="sitio"
                        placeholder="Sitio"
                        label="nombre"
                        @input="selectSitio()"
                        :options="listsForms.sitios"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Operación</label
                      ><v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="operacion_vh"
                        placeholder="Operación"
                        label="descripcion"
                        @input="selectOperacion()"
                        :options="$parent.listsForms.operacion_vehiculos"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Marca</label
                      ><v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        placeholder="Marca"
                        v-model="marca"
                        label="descripcion"
                        @input="selectMarca()"
                        :options="$parent.listsForms.marcas"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Modelo</label
                      ><v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        placeholder="Modelo"
                        v-model="modelo"
                        label="modelo"
                        :options="listsForms.modelos"
                        @input="selectModelo()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Estado</label
                      >
                      <select
                        class="form-control form-control-sm col-md-7 p-0"
                        v-model="filters.estado"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estadoOpc in $parent.listsForms.estados"
                          :key="estadoOpc.numeracion"
                          :value="estadoOpc.numeracion"
                        >
                          {{ estadoOpc.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group row">
                      <label
                        for="fecha_inicial"
                        class="col-md-5"
                        style="font-size: 20px"
                        >Fecha Ingreso</label
                      >
                      <input
                        type="date"
                        v-model="filters.fecha_ingreso"
                        class="form-control form-control-sm col-md-7"
                      />
                    </div>
                    <div class="form-group row">
                      <label
                        for="fecha_final"
                        class="col-md-5"
                        style="font-size: 20px"
                        >Fecha Salida</label
                      >
                      <input
                        type="date"
                        v-model="filters.fecha_salida"
                        class="form-control form-control-sm col-md-7"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="placa"
                              v-model="checks.placa"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="placa"
                              >Placa</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              v-model="checks.sitio"
                              id="sitio"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="sitio"
                              >Sitio</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              v-model="checks.area"
                              id="area"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="area"
                              >Area</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              v-model="checks.empresa"
                              id="empresa"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="empresa"
                              >Empresa</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="activo"
                              v-model="checks.activo"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="activo"
                              >Activo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="operacion_vh"
                              v-model="checks.operacion_vh"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="operacion_vh"
                              >Operacion Vehículo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_ingreso"
                              v-model="checks.fecha_ingreso"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_ingreso"
                              >Fecha Ingreso</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_salida"
                              v-model="checks.fecha_salida"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_salida"
                              >Fecha Salida</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="marca"
                              v-model="checks.marca"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="marca"
                              >Marca</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="modelo"
                              v-model="checks.modelo"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="modelo"
                              >Modelo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="clase_vehiculo"
                              v-model="checks.clase_vh"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="clase_vehiculo"
                              >Clase Vehículo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_vehiculo"
                              v-model="checks.tipo_vh"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_vehiculo"
                              >Tipo Vehículo</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="email"
                              v-model="checks.email"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="email"
                              >Email</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="check_estado"
                              v-model="checks.estado"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="check_estado"
                              >Estado</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="obvs"
                              v-model="checks.obvs"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="obvs"
                              >Observacion</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="created"
                              v-model="checks.fecha_creacion"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="created"
                              >Fecha Creación</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="created"
                              v-model="checks.obvs_es"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="created"
                              >Observaciones de estado</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer justify-content-between">
              <div>
                <button
                  type="button"
                  @click="getIndex()"
                  class="btn btn-sm bg-navy"
                >
                  Generar Listado
                  <i class="fas fa-file-download"> </i>
                </button>
              </div>
            </div>
          </div>

          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import moment from "moment";
export default {
  name: "SolicitudesVehiculosExport",
  components: { vSelect },
  data() {
    return {
      cargando: false,
      checks: {
        placa: true,
        sitio: true,
        empresa: true,
        area: true,
        activo: true,
        operacion_vh: true,
        fecha_ingreso: true,
        fecha_salida: true,
        marca: true,
        modelo: true,
        clase_vh: true,
        tipo_vh: true,
        email: true,
        estado: true,
        obvs: true,
        obvs_es: true,
        fecha_creacion: true,
      },
      filters: {
        placa: null,
        fecha_ingreso: null,
        fecha_salida: null,
        email: null,
        estado: null,
        fecha_creacion: null,
        modelo: null,
        tipo_vh_id: null,
        clase_vh_id: null,
        empresa_id: null,
        activo_id: null,
        area_id: null,
        sitio_id: null,
        operacion_id: null,
        marca_id: null,
      },
      empresa: null,
      area: null,
      activo: null,
      operacion_vh: null,
      sitio: null,
      marca: null,
      modelo: null,
      clase_vh: null,
      tipo_vh: null,
      listsForms: {
        modelos: [],
      },
    };
  },
  methods: {
    getIndex() {
      this.cargando = true;
      let datos = {
        filters: this.filters,
        checks: this.checks,
      };
      axios
        .post("api/hse/HseSolicitudesVehiculosExport", datos)
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
    getModelos() {
      let anioActual = moment().format("YYYY");
      anioActual = parseInt(anioActual) + 1;
      for (let i = 1970; i <= anioActual; i++) {
        this.listsForms.modelos.push({
          modelo: i,
        });
      }
    },
    getTipoVh() {
      axios
        .get("api/hse/HseSolicitudesVehiculos/listaTipoVh")
        .then((response) => {
          this.listsForms.tipos_vehiculos = response.data;
        });
    },

    getAreas() {
      axios.get("/api/lista/99").then((response) => {
        this.listsForms.areas = response.data;
      });
    },

    getSitios() {
      axios.get("/api/hse/listaSitios").then((response) => {
        this.listsForms.sitios = response.data;
      });
    },

    //Seleccionar solo ids
    selectEmpresa() {
      this.filters.empresa_id = null;
      if (this.empresa) {
        this.filters.empresa_id = this.empresa.numeracion;
      }
    },
    selectActivo() {
      this.filters.activo_id = null;
      if (this.activo) {
        this.filters.activo_id = this.activo.numeracion;
      }
    },

    selectArea() {
      this.filters.area_id = null;
      if (this.area) {
        this.filters.area_id = this.area.numeracion;
      }
    },

    selectTipoVh() {
      this.filters.tipo_vh_id = null;
      if (this.tipo_vh) {
        this.filters.tipo_vh_id = this.tipo_vh.id;
      }
    },

    selectClaseVh() {
      this.filters.clase_vh_id = null;
      if (this.clase_vh) {
        this.filters.clase_vh_id = this.clase_vh.numeracion;
      }
    },

    selectSitio() {
      this.filters.sitio_id = null;
      if (this.sitio) {
        this.filters.sitio_id = this.sitio.id;
      }
    },

    selectMarca() {
      this.filters.marca_id = null;
      if (this.marca) {
        this.filters.marca_id = this.marca.numeracion;
      }
    },

    selectOperacion() {
      this.filters.operacion_id = null;
      if (this.operacion_vh) {
        this.filters.operacion_id = this.operacion_vh.numeracion;
      }
    },

    selectModelo() {
      this.filters.modelo = null;
      if (this.modelo) {
        this.filters.modelo = this.modelo.modelo;
      }
    },
  },

  mounted() {
    this.getModelos();
    this.getTipoVh();
    this.getAreas();
    this.getSitios();
  },
};
</script>
